import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { GenerateNewRouteFunc, IRoute } from "@/hooks/useCalculateRoute"
import { triggerBulkDeliveryReassign } from "@/api/deliveries"
import { flatMap, groupBy, head, isEmpty, omit, uniq, values } from "lodash"
import { List, Skeleton, Switch, Tabs, Tree, message } from "antd"
import type { DataNode } from "antd/es/tree"
import { Driver, DriverMapPin } from "@/types/drivers.types"
import SearchFilter from "@/UI/Filters/SearchFilter"
import { useTranslation } from "react-i18next"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { ProvidersType } from "@/types/providers.types"
import { DisplayProvider, Loader } from "@/UI"
import { EnrichedTeam } from "@/types/teams.types"
import { DriverTreeTitle, BlastTreeTitle, UnassignedTreeTitle } from "./components/DriverTreeTitle"

import { locationsSelector, userAtom } from "@/atoms/userAtom"
import { useInterval, useWindowSize } from "usehooks-ts"
import {
  DispatchMapFilters,
  DriverGraph,
  MapOrder,
  OrderGraph,
  GraphIterable,
  UNASSIGNED_KEY_SLUG,
} from "@/types/map"
import { getMapData } from "@/api/map"
import { DateTime } from "luxon"
import { Zone } from "@/types/zones.types"
import { useFlags } from "flagsmith/react"
import MapDropdownActions from "./components/MapDropdownActions"
import MapOrderCard, { OrderAssignedTo } from "./components/MapOrderCard"
import MapStatusFilterSelect from "./components/MapStatusFilterSelect"
import MapTeamDriverZoneFilters from "./components/MapTeamDriverZoneFilters"
import { handleUpdateFleet } from "../new-orders/helpers"
import {
  createDraftOrdersDeliveries,
  createDraftOrdersQuotes,
  patchDraftOrdersFleet,
} from "@/api/draftorder"
import { AssignmentTypes } from "@/types/draftOrder.types"
import { useDispatchRule } from "@/hooks/useDispatchRule"
import { ACTIVE_ORDERS_DEFAULT_DATES, DRAFT_ORDERS_DEFAULT_DATES } from "@/hooks/useOrdersFilters"
import {
  DrawerOrder,
  defaultModalsState,
  dispatchPageModalsAtom,
} from "@/atoms/dispatchPageModalsAtom"
import DateFilter from "@/UI/Filters/DateFilter"
import axios from "axios"
import { DATE_FILTER_BY } from "@/utils/datetime"
import { defaultMapState, emptyOrderGraph, mapAtom } from "@/atoms/mapAtom"
import { useSandboxRouter } from "@/hooks/useSandboxRouter"
import {
  formatShortLocationToLocation,
  formatShortenedSenderToLocation,
} from "@/utils/stopsFormatter"
import { Location, OrderType } from "@/types/orders.types"
import { SINGLE_PIN_MAX_LIMIT } from "../shared/Map/components/MarkersComponents/UnassignedPins/UnassignedTasksRenderer"
import MoveStopWrapper from "../shared/MoveStop/MoveStopWrapper"
import KosmoButton from "@/UI/Buttons/KosmoButton"
import { KosmoMapContext } from "../shared/Map/MapProvider"
import { globalMapAtom } from "@/atoms/globalMapAtom"
import { createOrderModalVisibleAtom } from "@/atoms/createOrderModalAtom"
import { LOCAL_STORAGE_FILTERS_KEY, setFiltersToLocalStorage } from "@/utils/filters"

interface DispatchMapProps {
  teams: EnrichedTeam[]
  zones: Zone[]
  drivers: Driver[]
  mapFilters: DispatchMapFilters
  setMapFilters: Dispatch<SetStateAction<DispatchMapFilters>>
  treeOpenedKeys: string[]
  setTreeOpenedKeys: Dispatch<SetStateAction<string[]>>
  selectedRowsKeys: string[]
  setSelectedRowsKeys: Dispatch<SetStateAction<string[]>>
  multipleRoutes: IRoute[]
  generateNewRoute: GenerateNewRouteFunc
  removeRoute: (id: string, cleanCache?: boolean) => void
  removeAllRoutes: (cleanCache?: boolean) => void
  triggerRecompute?: boolean
}

interface RawTreeNodeFormat {
  // name property exists for 3PL
  name?: string
  id?: number
  type?: "3PL" | "driver" | "team"
  d?: Record<string, DriverGraph>
  o?: MapOrder[]
  t: number
  tr: number
  b?: OrderGraph
  u?: OrderGraph
  keySlug?: string
}

const { DirectoryTree } = Tree

enum DirectoryTreeKeys {
  NOT_LOADED = "not_loaded",
  LOADED = "loaded",
}

const orgEmptyRelatedFilters = {
  zones: [],
  teams: [],
  drivers: [],
  q: "",
}

export const emptyFilters: DispatchMapFilters = {
  type: OrderType.ACTIVE,
  dates: ACTIVE_ORDERS_DEFAULT_DATES,
  [OrderType.ACTIVE]: ACTIVE_ORDERS_DEFAULT_DATES,
  [OrderType.DRAFT]: DRAFT_ORDERS_DEFAULT_DATES,
  statuses: [],
  openedKeys: [],
  is_active: true,
  ...orgEmptyRelatedFilters,
  dateFilterBy: DATE_FILTER_BY.CREATION,
}

const DispatchMap: React.FC<DispatchMapProps> = ({
  teams,
  zones,
  drivers: allDrivers,
  mapFilters,
  setMapFilters,
  treeOpenedKeys,
  setTreeOpenedKeys,
  selectedRowsKeys,
  setSelectedRowsKeys,
  multipleRoutes,
  generateNewRoute,
  removeAllRoutes,
  removeRoute,
  triggerRecompute,
}) => {
  const { t } = useTranslation()

  const mapContext = useContext(KosmoMapContext)
  const setGlobalMapState = useSetRecoilState(globalMapAtom)
  /* Note: Can not have 2 instances of the map simultaneously, hence hide when create-order open */
  const { visible } = useRecoilValue(createOrderModalVisibleAtom)
  const [waitingToSelect, setWaitingToSelect] = useState<boolean>(false)
  const { sandboxRouter } = useSandboxRouter()
  const pollingController = useRef<AbortController>()
  const flags = useFlags(["show_unassigned_pins"])
  const setDispatchPageModals = useSetRecoilState(dispatchPageModalsAtom)
  const [processingDraftOrderId, setProcessingDraftOrderId] = useState<string | null>(null)
  const [isNodeLoading, setIsNodeLoading] = useState<string | null>()
  const [isVirtual, setIsVirtual] = useState<boolean>(true)
  const { org } = useRecoilValue(userAtom)
  const { dispatchRules } = useDispatchRule()
  const stores = useRecoilValue(locationsSelector)

  const [mapData, setMapData] = useRecoilState(mapAtom)
  // Force directory Tree regeneration
  const [treeKey, setTreeKey] = useState<string>(DirectoryTreeKeys.NOT_LOADED)
  const { height } = useWindowSize()
  const [showUnassignedTasks, setShowUnassignedTasks] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [hasFetched, setHasFetched] = useState<boolean>(false)
  const routesRef = useRef<IRoute[]>([])

  useEffect(() => {
    routesRef.current = multipleRoutes
  }, [multipleRoutes])

  const driverPins = allDrivers?.map(
    (driver: Driver): DriverMapPin => ({
      ...driver,
      latitude: mapData?.driverPins[driver?.id]?.coordinates?.latitude || driver?.latitude,
      longitude: mapData?.driverPins[driver?.id]?.coordinates?.longitude || driver?.longitude,
      locationUpdatedAt:
        mapData?.driverPins[driver?.id]?.lastUpdatedAt || driver?.locationUpdatedAt,
      onDriverPinClick: () => {
        const driverSlugs = [
          `org:${driver.id}`,
          ...(driver?.teamIDs?.map((teamID) => `${teamID}:${driver.id}`) || []),
        ]
        setTreeOpenedKeys(driverSlugs)
        setMapFilters((mapFilters) => ({
          ...mapFilters,
          drivers: [driver.id],
        }))
        scrollToTreeNode(driver.id)
      },
      onDriverPinCloseClick: () => {
        setTreeOpenedKeys([])
        setMapFilters((mapFilters) => ({ ...mapFilters, drivers: [] }))
      },
    })
  )

  const fetchMapData = async (isPolling?: boolean) => {
    try {
      const isFreshData =
        !!mapData?.lastUpdatedAt &&
        mapData?.lastUpdatedAt?.isValid &&
        mapData?.lastUpdatedAt.plus({ minutes: 2 }) > DateTime.now() &&
        mapFilters?.type === mapData?.type

      const skipInitialLoading = !hasFetched && isFreshData
      if (skipInitialLoading && !isPolling) {
        setHasFetched(true)
      }

      if (!isPolling && !skipInitialLoading) {
        setIsLoading(true)
      }

      if (pollingController?.current) {
        pollingController?.current?.abort()
      }
      pollingController.current = new AbortController()
      const apiNodeKeys = mapFilters?.openedKeys?.filter(
        (openKey) => openKey?.split(":")?.length === 2 || openKey === UNASSIGNED_KEY_SLUG
      )

      // Map date range are formatted before sending the query params to get a daily range
      const { data } = await getMapData(
        {
          ...mapFilters,
          openedKeys: apiNodeKeys,
        },
        pollingController?.current?.signal
      )
      setHasFetched(true)
      setMapData({
        ...data,
        type: mapFilters?.type,
        lastUpdatedAt: DateTime.now(),
      })

      if (waitingToSelect && !isPolling) {
        const graphIterable = new GraphIterable(data?.graph)
        const newSelectedOrderIds: string[] = []
        graphIterable?.map((order) => {
          newSelectedOrderIds?.push(order?.id)
        })
        setSelectedRowsKeys(newSelectedOrderIds)
      }
      setWaitingToSelect(false)
      setTreeKey(DirectoryTreeKeys.LOADED)
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.log(error)
        message.error(t("errors.common"))
      }
    } finally {
      setIsNodeLoading(null)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    removeAllRoutes()
    if (teams?.length === 1 && teams?.[0]) {
      setTreeOpenedKeys([teams?.[0]?.id?.toString()])
    } else if (teams?.length === 0) {
      setTreeOpenedKeys(["org"])
    }
    return () => {
      setTreeOpenedKeys((treeKeyState) =>
        treeKeyState.filter((treeKey) => treeKey !== UNASSIGNED_KEY_SLUG)
      )
    }
  }, [])

  useEffect(() => {
    let timeoutId: NodeJS.Timeout
    setIsVirtual(false)
    const queryNodeKeys = sandboxRouter?.query?.keys
    const queryOrderIds = sandboxRouter?.query?.ids
    if (queryOrderIds && queryNodeKeys) {
      const parsedKeys =
        typeof queryNodeKeys === "string"
          ? queryNodeKeys?.split("/")
          : queryNodeKeys?.join("/").split("/")
      const parsedIds =
        typeof queryOrderIds === "string"
          ? queryOrderIds?.split("/")
          : queryOrderIds?.join("/").split("/")

      setTreeOpenedKeys(parsedKeys)
      setSelectedRowsKeys(parsedIds)
      setMapFilters((mapFiltersState) => ({
        ...mapFiltersState,
        type: OrderType.DRAFT,
        openedKeys: treeOpenedKeys,
        dates: {
          ...mapFiltersState?.dates,
          range: [
            DateTime?.now()?.toFormat(mapFiltersState?.dates?.format),
            DateTime?.now()?.toFormat(mapFiltersState?.dates?.format),
          ],
        },
      }))
    }
    timeoutId = setTimeout(() => setIsVirtual(true), 2000)
    sandboxRouter.push({ pathname: sandboxRouter.pathname, query: null }, undefined, {
      shallow: true,
    })
    return () => {
      clearTimeout(timeoutId)
    }
  }, [hasFetched])

  useEffect(() => {
    if (mapFilters?.type === OrderType.ACTIVE) {
      setShowUnassignedTasks(false)
    }
  }, [mapFilters?.type])

  useEffect(() => {
    // On a page navigation, this useEffect is always triggered on first render - check orgId
    const previousFilters = JSON.parse(localStorage?.getItem(LOCAL_STORAGE_FILTERS_KEY) || "{}")?.[
      org?.id
    ]
    if (!isEmpty(previousFilters) && org?.id !== previousFilters?.orgId) {
      const typeDateFilters =
        previousFilters.type === OrderType.ACTIVE
          ? previousFilters[OrderType.ACTIVE]
          : previousFilters[OrderType.DRAFT]
      setMapFilters((currentFilters) => ({
        ...currentFilters,
        ...orgEmptyRelatedFilters,
        dates: typeDateFilters,
      }))
      setHasFetched(false)
      setMapData(defaultMapState)
      setTreeKey(Math.random().toString())
    }
    setShowUnassignedTasks(false)
    removeAllRoutes()
  }, [org.id])

  const onDriverChange = (selectedDrivers: string[], isClear?: boolean) => {
    if (isClear) {
      setMapFilters((mapFiltersState) => {
        return { ...mapFiltersState, drivers: [], openedKeys: [] }
      })
      setTreeOpenedKeys([])
      return
    }

    setMapFilters((mapFiltersState) => {
      let newOpenedKeys: string[] = []
      if (selectedDrivers?.length > 0) {
        const driversKeys = selectedDrivers?.map((driver) => {
          const completeDriver = allDrivers?.find((driverDetails) => driverDetails?.id === driver)
          const driverSlugs =
            completeDriver?.teamIDs?.map((teamID) => `${teamID}:${completeDriver.id}`) || []
          return driverSlugs
        })
        newOpenedKeys = flatMap(driversKeys)
      }

      setTreeOpenedKeys(newOpenedKeys)
      return { ...mapFiltersState, drivers: selectedDrivers }
    })
  }

  const onTeamChange = (selectedTeams: string[], isClear?: boolean) => {
    if (isClear) {
      setMapFilters((mapFiltersState) => ({ ...mapFiltersState, teams: [] }))
      setTreeOpenedKeys([])
    }

    setMapFilters((mapFiltersState) => ({ ...mapFiltersState, teams: selectedTeams }))
    setTreeOpenedKeys(selectedTeams?.map((team) => team?.toString()))
  }

  useEffect(() => {
    fetchMapData()

    setFiltersToLocalStorage(
      {
        ...omit(mapFilters, ["q"]),
        orgId: org.id,
        is_active: true,
        ttl: DateTime.now().plus({ minutes: 10 }).toMillis(),
        ...(mapFilters.type === OrderType.ACTIVE ? { [OrderType.ACTIVE]: mapFilters.active } : {}),
        ...(mapFilters.type === OrderType.DRAFT ? { [OrderType.DRAFT]: mapFilters.draft } : {}),
      },
      org?.id
    )
  }, [mapFilters, triggerRecompute])

  useEffect(() => {
    fetchMapData(true)
  }, [triggerRecompute])

  // Filters out unecessary keys and avoid request on collapse
  useEffect(() => {
    const nodeApiKeys = treeOpenedKeys?.filter(
      (key) => key?.includes(":") || key === UNASSIGNED_KEY_SLUG
    )
    // if there are some nodeApiKeys that are not in the mapFilters, we need to fetch the data
    if (!nodeApiKeys?.every((key) => mapFilters?.openedKeys?.includes(key))) {
      setMapFilters((mapFiltersState) => ({ ...mapFiltersState, openedKeys: nodeApiKeys }))
    } else {
      // Set selected orders even when open tree node didn't change
      if (waitingToSelect) {
        const graphIterable = new GraphIterable(mapData?.graph)
        const newSelectedOrderIds: string[] = []
        graphIterable?.map((order) => {
          newSelectedOrderIds?.push(order?.id)
        })
        setSelectedRowsKeys(newSelectedOrderIds)
        setWaitingToSelect(false)
      }
    }
  }, [treeOpenedKeys])

  useInterval(() => {
    setWaitingToSelect(false)
    fetchMapData(true)
  }, 30000)

  const [openedOrders, setOpenedOrders] = useState<string[]>([])

  const graphIterable = new GraphIterable(mapData.graph)

  // Used below
  const reassignDriverTeam = async (orderId: string, driverId: string, teamId?: number) => {
    try {
      if (mapFilters.type === OrderType.DRAFT) {
        await patchDraftOrdersFleet(
          { draftOrdersIds: [orderId] },
          {
            driverId,
            teamId,
          }
        )
      } else {
        await triggerBulkDeliveryReassign({
          type: driverId ? "driver" : "assignmentType",
          orderIds: [orderId],
          teamId: teamId,
          driverId,
          assignmentType: driverId ? AssignmentTypes.MANUAL : AssignmentTypes.BLAST,
        })
      }
    } catch (error) {
      throw error
    }
  }

  const reassignRule = async (orderId: string, rule: string) => {
    if (mapFilters.type === OrderType.DRAFT) {
      await createDraftOrdersQuotes([orderId], rule)
    } else {
      await triggerBulkDeliveryReassign({
        type: "rule",
        orderIds: [orderId],
        ruleId: rule === "cheapest" ? "" : rule,
      })
    }
  }

  const handleSelectDriverOrTeam = async (reassignTarget: string, orderId: string) => {
    try {
      const ruleType = reassignTarget?.split("_")?.[0] || ""
      const isTeamOrDriver = ["team", "driver"].includes(ruleType)
      if (isTeamOrDriver) {
        const driverId = ruleType === "driver" ? reassignTarget : ""
        const teamId =
          ruleType === "team"
            ? Number(reassignTarget?.split("_")?.[1]!)
            : Number(allDrivers?.find((driver) => driver?.id === driverId)?.teamIDs?.[0]) ||
              undefined
        await reassignDriverTeam(orderId, driverId, teamId)
        const newKeys = teamId
          ? [teamId?.toString(), driverId ? `${teamId}:${driverId}` : `${teamId}:blast`]
          : ["org", `org:${driverId}`]
        if (newKeys) {
          setTimeout(async () => {
            setTreeOpenedKeys((treeOpenedKeysState) => uniq([...treeOpenedKeysState, ...newKeys]))
          }, 700)
        }
      } else {
        await reassignRule(orderId, reassignTarget)
      }
      message.success(t("common.reassign.successfullyReassigned"))
      await fetchMapData()
      setSelectedRowsKeys((selectedRowsKeysState) => uniq([...selectedRowsKeysState, orderId]))
    } catch (error) {
      message.error(t("common.reassign.failedReassigned"))
    }
  }

  const handleAssignmentSelect = async (
    teamId: number,
    assignmentType: AssignmentTypes,
    orderId: string
  ) => {
    try {
      if (mapFilters.type === OrderType.ACTIVE) {
        await triggerBulkDeliveryReassign({
          type: "assignmentType",
          orderIds: [orderId],
          teamId: teamId,
          assignmentType,
        })
      } else {
        await handleUpdateFleet({ draftOrdersIds: [orderId] }, { teamId: teamId, assignmentType })
      }
      message.success(t("common.reassign.successfullyReassigned"))
      await fetchMapData()
    } catch (error) {
      message.error(t("common.notification.somethingWentWrongDetails"))
    }
  }

  const handleDraftOrderConfirm = async (orderId: string) => {
    setProcessingDraftOrderId(orderId)
    try {
      await createDraftOrdersDeliveries({ draftOrdersIds: [orderId] })
      message.success(t("common.successfullyCreatedOrder"))
      await fetchMapData()
      removeRoute(orderId)
    } catch (error) {
      message.error(t("common.notification.somethingWentWrongDetails"))
    } finally {
      setProcessingDraftOrderId(null)
    }
  }

  const memoTree = useMemo(() => {
    const unassignedOrders = {
      ...mapData?.graph?.unnassigned,
      type: UNASSIGNED_KEY_SLUG,
      name: t("common.unassigned"),
      keySlug: UNASSIGNED_KEY_SLUG,
    }

    const noTeamDriversOrders = {
      ...mapData?.graph?.teams?.find((team) => team.id === 0),
      type: "no_team",
      name: org?.name,
      teamID: "org",
      keySlug: "org",
    }

    // Show org tree node if no-team drivers have routes assigned or there is no teams in org
    const isOrgNodeVisible = (noTeamDriversOrders.tr || 0) > 0 || isEmpty(teams)
    const firstTreeItems = isOrgNodeVisible
      ? [unassignedOrders, noTeamDriversOrders]
      : [unassignedOrders]

    const treeTeams = mapData?.graph?.teams
      ?.filter((team) => team.id !== 0)
      ?.sort((teamA, teamB) => ((teamA?.id || 0) > (teamB?.id || 0) ? -1 : 1))

    const mainTreeNodes = [...firstTreeItems, ...treeTeams] as RawTreeNodeFormat[]
    if (mapData?.graph?.thirdParty?.t > 0) {
      const ordersByCourier = groupBy(mapData?.graph?.thirdParty?.o, (order) => order?.p)
      const couriersNode = Object.values(ProvidersType)
        .map(
          (provider) =>
            ({
              name: provider,
              type: "3PL",
              keySlug: `3pl:${provider}`,
              o: ordersByCourier[provider],
              t: ordersByCourier[provider]?.length,
            } as RawTreeNodeFormat)
        )
        ?.filter((courier) => courier?.t > 0)
        ?.sort((a, b) => b.t - a.t)
      mainTreeNodes?.push(...couriersNode)
    }

    // Tips: Ant-tree-title custom css to 97% and avoid scroll bar
    const treeData = mainTreeNodes?.map((team, teamIndex): DataNode => {
      const unnassignedTeamGraph = team.u

      const graphDrivers = values(team?.d)
        ?.sort((driverA, driverB) => {
          const totalIdSorting =
            driverA?.o?.t === driverB?.o?.t
              ? driverA?.id.localeCompare(driverB?.id) > 0
              : driverA?.o?.t > driverB?.o?.t

          return totalIdSorting ? -1 : 1
        })
        ?.filter((driver) => {
          if (mapFilters.drivers.length > 0) {
            return mapFilters.drivers.includes(driver.id)
          }
          return true
        })

      const teamBlast = team?.b || emptyOrderGraph()
      const teamOrders = team?.t || 0
      const teamRoutes = team?.tr || 0
      const teamDetails = teams?.find((fullTeam) => fullTeam.id === team?.id!)
      const teamName = teamDetails?.name || team?.name
      const teamKeySlug = team?.keySlug || team?.id?.toString()
      const unassignedKeySlug = `${teamKeySlug}:${UNASSIGNED_KEY_SLUG}`
      const blastKeySlug = `${teamKeySlug}:blast`
      const unassignedPinsLength = values(mapData?.taskPins)?.length

      return {
        title: (
          <div className="flex flex-col font-semibold capitalize">
            <div className="flex gap-x-2">
              {team?.type === "3PL" ? (
                <DisplayProvider provider={team?.name as ProvidersType} className="mt-0.5" />
              ) : (
                <p>{teamName}</p>
              )}
              {isNodeLoading === teamKeySlug && <Loader fontSize={12} />}
            </div>
            <p className="text-sm text-gray-500 font-normal">
              {teamRoutes} {t("common.routes")} | {teamOrders} {t("common.stops").toLowerCase()}
            </p>
          </div>
        ),
        // TODO: check typing here
        key: teamKeySlug!,
        children:
          graphDrivers?.length || teamBlast?.t > 0 || unnassignedTeamGraph
            ? ([
                !!unnassignedTeamGraph &&
                  unnassignedTeamGraph.t > 0 && {
                    title: <UnassignedTreeTitle total={unnassignedTeamGraph.t} />,
                    key: unassignedKeySlug,
                    children: !isEmpty(unnassignedTeamGraph.o)
                      ? values(unnassignedTeamGraph?.o)
                          ?.sort((orderA, orderB) => (orderA.ca > orderB.ca ? -1 : 1))
                          ?.map((order) => {
                            return {
                              title: (
                                <MapOrderCard
                                  order={order}
                                  teams={teams}
                                  drivers={allDrivers}
                                  handleSelect={(rule: string) =>
                                    handleSelectDriverOrTeam(rule, order.id)
                                  }
                                  handleAssignmentSelect={(
                                    teamId: number,
                                    assignmentType: AssignmentTypes
                                  ) => handleAssignmentSelect(teamId, assignmentType, order.id)}
                                  dispatchRules={dispatchRules}
                                  selectedRowsKeys={selectedRowsKeys}
                                  setOpenedOrders={setOpenedOrders}
                                  removeRoute={removeRoute}
                                  generateNewRoute={generateNewRoute}
                                  setSelectedRowsKeys={setSelectedRowsKeys}
                                  openedOrders={openedOrders}
                                  assignedTo={OrderAssignedTo.NONE}
                                  draftOrderConfirmInProgress={processingDraftOrderId === order.id}
                                  handleConfirmDraftOrder={() => handleDraftOrderConfirm(order.id)}
                                />
                              ),
                              key: order.oid,
                              selectable: false,
                            }
                          })
                      : unnassignedTeamGraph.t > 0
                      ? [
                          {
                            title: (
                              <Skeleton
                                active
                                paragraph={{ rows: 2 }}
                                style={{ width: "90%" }}
                                title={false}
                              />
                            ),
                            key: unassignedKeySlug + "_empty",
                            selectable: false,
                          },
                        ]
                      : [],
                  },
                teamBlast.t > 0 && {
                  title: (
                    <BlastTreeTitle
                      total={teamBlast.t}
                      routesCount={teamBlast.tr}
                      isNodeLoading={isNodeLoading === blastKeySlug}
                    />
                  ),
                  key: blastKeySlug,
                  children: !isEmpty(teamBlast.o)
                    ? values(teamBlast.o)
                        ?.sort((orderA, orderB) => (orderA?.ca > orderB?.ca ? -1 : 1))
                        ?.map((order) => {
                          return {
                            title: (
                              <MapOrderCard
                                order={order}
                                teams={teams}
                                drivers={allDrivers}
                                handleSelect={(rule: string) =>
                                  handleSelectDriverOrTeam(rule, order.id)
                                }
                                handleAssignmentSelect={(
                                  teamId: number,
                                  assignmentType: AssignmentTypes
                                ) => handleAssignmentSelect(teamId, assignmentType, order.id)}
                                dispatchRules={dispatchRules}
                                selectedRowsKeys={selectedRowsKeys}
                                setOpenedOrders={setOpenedOrders}
                                removeRoute={removeRoute}
                                generateNewRoute={generateNewRoute}
                                setSelectedRowsKeys={setSelectedRowsKeys}
                                openedOrders={openedOrders}
                                assignedTo={OrderAssignedTo.TEAM}
                                draftOrderConfirmInProgress={processingDraftOrderId === order.id}
                                handleConfirmDraftOrder={() => handleDraftOrderConfirm(order.id)}
                              />
                            ),
                            key: order.oid,
                            selectable: false,
                          }
                        })
                    : teamBlast.t > 0
                    ? [
                        {
                          title: (
                            <Skeleton
                              active
                              paragraph={{ rows: 2 }}
                              style={{ width: "80%" }}
                              title={false}
                            />
                          ),
                          key: blastKeySlug + "_empty",
                          selectable: false,
                        },
                      ]
                    : [],
                },
                ...graphDrivers.map((driver, driverIndex) => {
                  const currentDriver = allDrivers.find((d) => d.id === driver.id)
                  if (!currentDriver) {
                    return null
                  }

                  const nodeKeySlug = `${teamKeySlug}:${driver.id}`
                  return {
                    title: (
                      <DriverTreeTitle
                        driver={currentDriver}
                        total={driver.o?.t || 0}
                        totalWeight={driver.o?.tw}
                        completed={driver.o?.c || 0}
                        returned={driver.o?.r || 0}
                        failed={driver.o?.f || 0}
                        routesCount={driver?.o.tr || 0}
                        showProgress={mapFilters.type === OrderType.ACTIVE}
                        isNodeLoading={isNodeLoading === nodeKeySlug}
                      />
                    ),
                    key: nodeKeySlug,
                    children: !isEmpty(driver?.o?.o)
                      ? values(driver?.o?.o)
                          ?.sort((orderA, orderB) => (orderA?.ca > orderB?.ca ? -1 : 1))
                          ?.map((order, orderIndex) => {
                            return {
                              title: (
                                <div
                                  style={{
                                    // Add bottom to last card so it's visible in scroll
                                    marginBottom:
                                      teamIndex === mainTreeNodes.length - 1 &&
                                      driverIndex === graphDrivers.length - 1 &&
                                      orderIndex === driver?.o?.o?.length - 1
                                        ? 70
                                        : 0,
                                    width: "97%",
                                  }}
                                >
                                  <MapOrderCard
                                    assignedDriver={currentDriver}
                                    order={order}
                                    teams={teams}
                                    drivers={allDrivers}
                                    handleSelect={(rule: string) =>
                                      handleSelectDriverOrTeam(rule, order.id)
                                    }
                                    handleAssignmentSelect={(
                                      teamId: number,
                                      assignmentType: AssignmentTypes
                                    ) => handleAssignmentSelect(teamId, assignmentType, order.id)}
                                    dispatchRules={dispatchRules}
                                    selectedRowsKeys={selectedRowsKeys}
                                    setOpenedOrders={setOpenedOrders}
                                    removeRoute={removeRoute}
                                    generateNewRoute={generateNewRoute}
                                    setSelectedRowsKeys={setSelectedRowsKeys}
                                    openedOrders={openedOrders}
                                    assignedTo={OrderAssignedTo.DRIVER}
                                    draftOrderConfirmInProgress={
                                      processingDraftOrderId === order.id
                                    }
                                    handleConfirmDraftOrder={() =>
                                      handleDraftOrderConfirm(order.id)
                                    }
                                  />
                                </div>
                              ),
                              key: order.oid,
                              selectable: false,
                            }
                          })
                      : driver?.o?.t > 0
                      ? [
                          {
                            title: (
                              <Skeleton
                                active
                                paragraph={{ rows: 2 }}
                                style={{ width: "80%" }}
                                title={false}
                              />
                            ),
                            key: nodeKeySlug + driver.id + "_empty",
                            selectable: false,
                          },
                        ]
                      : [],
                  }
                }),
              ].filter(Boolean) as any) // Create a function to generate the node
            : // 3PL part & Unassigned
            !isEmpty(team?.o)
            ? values(team?.o)
                ?.sort((orderA, orderB) => {
                  if (unassignedPinsLength > SINGLE_PIN_MAX_LIMIT) {
                    const selectedSorting =
                      selectedRowsKeys.indexOf(orderB.id) - selectedRowsKeys.indexOf(orderA.id)
                    return selectedSorting
                  } else {
                    const createdAtSorting = orderA.ca > orderB.ca ? -1 : 1
                    return createdAtSorting
                  }
                })
                ?.map((order) => {
                  return {
                    title: (
                      <MapOrderCard
                        order={order}
                        teams={teams}
                        drivers={allDrivers}
                        handleSelect={(rule: string) => handleSelectDriverOrTeam(rule, order.id)}
                        handleAssignmentSelect={(teamId: number, assignmentType: AssignmentTypes) =>
                          handleAssignmentSelect(teamId, assignmentType, order.id)
                        }
                        dispatchRules={dispatchRules}
                        selectedRowsKeys={selectedRowsKeys}
                        setOpenedOrders={setOpenedOrders}
                        removeRoute={removeRoute}
                        generateNewRoute={generateNewRoute}
                        setSelectedRowsKeys={setSelectedRowsKeys}
                        openedOrders={openedOrders}
                        assignedTo={
                          team.type === "3PL" ? OrderAssignedTo.THIRD_PARTY : OrderAssignedTo.NONE
                        }
                        draftOrderConfirmInProgress={processingDraftOrderId === order.id}
                        handleConfirmDraftOrder={() => handleDraftOrderConfirm(order.id)}
                      />
                    ),
                    key: order.oid,
                    selectable: false,
                  }
                })
            : team?.t > 0
            ? [
                {
                  title: (
                    <Skeleton
                      active
                      paragraph={{ rows: 2 }}
                      style={{ width: "80%" }}
                      title={false}
                    />
                  ),
                  key: UNASSIGNED_KEY_SLUG + "_empty",
                  selectable: false,
                },
              ]
            : [],
      }
    })
    return treeData
  }, [
    mapData,
    openedOrders,
    selectedRowsKeys,
    processingDraftOrderId,
    isNodeLoading,
    treeOpenedKeys,
    org?.id,
    allDrivers,
    teams,
  ])

  const sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  const scrollToTreeNode = async (nodeId: string) => {
    await sleep(200)
    const container = Array.from(
      document.getElementsByClassName("ant-tree-list-holder") as HTMLCollectionOf<HTMLElement>
    )?.[0]
    const scrollHistory = []
    if (container) {
      container.scrollTo({ top: 10, behavior: "auto" })
      let abort = false
      let targetNode
      while (!abort) {
        targetNode = document.getElementById(nodeId)
        if (!!targetNode) {
          targetNode.scrollIntoView()
          break
        }
        container?.scrollTo({
          top: container.scrollTop + 100,
          left: 0,
          behavior: "auto",
        })
        scrollHistory.push(container.scrollTop)
        // We stop scrolling if it has the same position twice
        abort =
          scrollHistory?.length > 5 &&
          scrollHistory[scrollHistory.length - 1] === scrollHistory[scrollHistory.length - 2]
        if (abort) break
        await sleep(0.0000002)
      }
    }
  }

  const flatMappedTasks = flatMap(mapData?.taskPins, (task, index) => {
    return { ...task, taskKey: index }
  })
  const myRef = useRef(null)
  const unassignedTasksPins = flatMappedTasks
    ?.filter((task) => !task?.driverID)
    ?.map((task) => ({
      ...task,
      visible: !selectedRowsKeys.includes(task.orderID),
      adjustStopComponent: (stop: { id: string; index: number; orderId: string }) => (
        <MoveStopWrapper
          routesRef={routesRef}
          filters={mapFilters}
          stop={stop}
          callback={(sourceOrderId, targetOrderId) => {
            removeRoute(sourceOrderId)
            removeRoute(targetOrderId)
            fetchMapData().then(() => {
              setSelectedRowsKeys((selectedRowsState) => {
                return uniq([...selectedRowsState, sourceOrderId, targetOrderId])
              })
            })
          }}
        />
      ),
      onPinClickOpen: async () => {
        setSelectedRowsKeys((selectedRowsKeys) => [...selectedRowsKeys, task.orderID])

        if (!mapFilters?.openedKeys?.includes(UNASSIGNED_KEY_SLUG)) {
          setMapFilters((mapFiltersState) => ({
            ...mapFiltersState,
            openedKeys: [UNASSIGNED_KEY_SLUG],
          }))
        }
        if (unassignedTasksPins.length < SINGLE_PIN_MAX_LIMIT) {
          setOpenedOrders([task.orderID])
        }
      },
      onPinClickClose: () => {
        removeRoute(task.orderID)
        setSelectedRowsKeys((selectedRowsKeysState) =>
          selectedRowsKeysState.filter((rowKey) => rowKey !== task.orderID)
        )
        setOpenedOrders([])
      },
      onPinEditStopClick: () => {
        const targetOrder = mapData.graph.unnassigned.o.find((order) => order.id === task.orderID)
        if (!targetOrder) return
        const recipients = targetOrder?.tt?.reduce((previousValue, currentValue) => {
          return [...previousValue, formatShortLocationToLocation(currentValue?.r)]
        }, [] as Location[])

        const newOrder = {
          ...targetOrder,
          id: targetOrder?.id,
          orderID: targetOrder?.oid,
          sender: formatShortenedSenderToLocation(targetOrder.sn),
          recipients: recipients,
          type: targetOrder.t,
          ...(targetOrder.t === OrderType.ACTIVE
            ? { priceBreakdown: { distance: targetOrder.m.td } }
            : { metadata: { totalDistance: targetOrder.m.td } }),
        } as unknown as DrawerOrder

        setDispatchPageModals({
          ...defaultModalsState,
          editOrderDrawerInstance: newOrder,
          defaultOpenAddressOrRef:
            task.recipient?.orderReferenceId || task.recipient?.location?.address,
        })
      },
    }))

  const handleSelectType = (type: OrderType) => {
    const previousFilters = JSON.parse(localStorage?.getItem(LOCAL_STORAGE_FILTERS_KEY) || "{}")?.[
      org?.id
    ]
    setMapFilters((filters) => ({
      ...filters,
      type: type,
      openedKeys: [],
      dates: previousFilters?.[type] ? previousFilters?.[type] : filters.dates,
    }))
    setTreeOpenedKeys([])
    setSelectedRowsKeys([])
    removeAllRoutes()
  }

  const onCollapse = () => {
    setTreeOpenedKeys([])
    setMapFilters((mapFiltersState) => {
      return { ...mapFiltersState, openedKeys: [] }
    })
  }

  const onSetDate = (date: string) => {
    setMapFilters((mapFiltersState) => {
      const synchronizedRange =
        mapFiltersState?.type === OrderType.ACTIVE
          ? [date, date]
          : [mapFiltersState?.[mapFiltersState?.type]?.range?.[0]!, date]
      removeAllRoutes()
      return {
        ...mapFiltersState,
        dates: { ...mapFiltersState.dates, range: [date, date] },
        [mapFiltersState?.type]: {
          ...mapFiltersState?.[mapFiltersState?.type],
          range: synchronizedRange,
        },
      }
    })
  }

  const handleConfirmOrders = async () => {
    const hasUnassigned = mapData.graph.unnassigned.o.some((o) => selectedRowsKeys.includes(o.id))
    if (hasUnassigned) {
      return
    }

    setIsLoading(true)
    try {
      await createDraftOrdersDeliveries({ draftOrdersIds: selectedRowsKeys })
      message.success(t("common.successfullyCreatedOrder"))
      await fetchMapData()
      setTreeOpenedKeys((treeOpenedKeysState) =>
        treeOpenedKeysState?.filter((key) => !selectedRowsKeys?.includes(key))
      )
      setSelectedRowsKeys([])
      removeAllRoutes()
    } catch (error) {
      message.error(t("errors.common"))
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (visible) {
      return
    }

    setGlobalMapState({
      current: {
        isMulti: true,
        drivers: driverPins,
        ...(showUnassignedTasks ? { tasks: unassignedTasksPins } : {}),
        defaultCenter: { lat: head(stores)?.latitude || 0, lng: head(stores)?.longitude || 0 },
        routes:
          multipleRoutes
            ?.filter((route) => route?.active)
            ?.map((route) => ({
              ...route,
              ...(route?.dropoffs
                ? {
                    adjustStopComponent: (stop: { id: string; index: number; orderId: string }) => (
                      <MoveStopWrapper
                        routesRef={routesRef}
                        filters={mapFilters}
                        stop={stop}
                        callback={(sourceOrderId, targetOrderId) => {
                          removeRoute(sourceOrderId)
                          removeRoute(targetOrderId)
                          fetchMapData().then(() => {
                            // setTreeKey(Math.random().toString())
                            setSelectedRowsKeys((selectedRowsState) => {
                              return uniq([...selectedRowsState, sourceOrderId, targetOrderId])
                            })
                          })
                        }}
                      />
                    ),
                  }
                : {}),
            })) || [],
      },

      prev: null,
    })
  }, [driverPins, multipleRoutes, unassignedTasksPins, visible])

  return (
    <div className="flex flex-row h-[93.5vh]">
      <div className="sm:w-5/12 lg:w-4/12 flex flex-col pl-5 pt-1.5 gap-y-4">
        <div className="flex w-full items-center justify-between gap-x-2 pr-2">
          <div className="flex flex-grow">
            <SearchFilter
              handleSearchValue={(value) => setMapFilters((state) => ({ ...state, q: value }))}
              size="middle"
              withAutocomplete={false}
              fluid
              isLoading={isLoading && !isNodeLoading}
            />
          </div>
          <MapDropdownActions
            setSelectedRowsKeys={setSelectedRowsKeys}
            removeAllRoutes={removeAllRoutes}
            onCollapse={onCollapse}
            setTreeOpenedKeys={setTreeOpenedKeys}
            setWaitingToSelect={setWaitingToSelect}
            isShowAllRoutesDisabled={false}
          />
          {OrderType.DRAFT === mapFilters.type && selectedRowsKeys.length > 0 && (
            <KosmoButton
              onClick={handleConfirmOrders}
              size="middle"
              type="primary"
              classname="text-sm"
              isLoading={isLoading}
              isDisabled={isLoading}
            >
              <div className="flex items-center">
                {
                  <span className="text-sm">
                    {t("common.sendToDrivers")} ({selectedRowsKeys?.length})
                  </span>
                }
              </div>
            </KosmoButton>
          )}
        </div>
        <div className="w-full">
          <Tabs
            moreIcon={null}
            defaultActiveKey={OrderType.DRAFT}
            activeKey={mapFilters.type}
            onChange={(key) => handleSelectType(key as OrderType)}
            className="w-full dispatch-map-tabs"
            // tabBarStyle={{ width: "100%" }}
            size="large"
            animated
            items={[
              { key: OrderType.DRAFT, label: t("common.type.plan") },
              { key: OrderType.ACTIVE, label: t("common.active") },
            ]}
          />
        </div>
        {hasFetched ? (
          <DirectoryTree
            treeData={memoTree}
            selectable={false}
            ref={myRef}
            rootClassName="w-full mb-10"
            key={treeKey}
            blockNode
            showIcon={false}
            motion={0}
            virtual={isVirtual}
            {...(height ? { height: height * 0.85 } : {})}
            checkStrictly
            autoExpandParent={true}
            defaultExpandParent={true}
            defaultExpandedKeys={treeOpenedKeys}
            expandedKeys={treeOpenedKeys}
            onExpand={(_, { expanded, node }) => {
              const currentNode = node?.key?.toString()
              if (expanded) {
                setTreeOpenedKeys((openedKeysState) => uniq([...openedKeysState, currentNode]))
                const shouldNodeLoad =
                  (currentNode?.includes(":") || currentNode === UNASSIGNED_KEY_SLUG) &&
                  !mapFilters?.openedKeys?.includes(currentNode)
                setIsNodeLoading(shouldNodeLoad ? currentNode : null)
              } else {
                setTreeOpenedKeys((openedKeysState) =>
                  openedKeysState.filter((key) => {
                    const keysToCollapse = [
                      currentNode,
                      ...(node?.children?.map((child) => child?.key?.toString()) || []),
                    ]

                    return !keysToCollapse.includes(key)
                  })
                )
                graphIterable
                  ?.filter(
                    (order) =>
                      selectedRowsKeys?.includes(order?.id) &&
                      !!node?.children?.find((child) => child.key.toString() === order.id)
                  )
                  .forEach((order) => {
                    removeRoute(order?.id)
                  })
              }
            }}
          />
        ) : (
          <div className="flex w-11/12 flex-col">
            {[0, 1, 2, 3, 4, 5, 6].map((_, index) => (
              <Skeleton loading={true} active avatar={{ shape: "square" }} key={index}>
                <List.Item.Meta
                  title={<a href={"item.href"}>{"item.title"}</a>}
                  description={"item.description"}
                />
                {"item.content"}
              </Skeleton>
            ))}
          </div>
        )}
      </div>
      <div className="relative sm:w-7/12 lg:w-8/12 w-full">
        <div
          className="flex absolute items-center w-full justify-between"
          style={{ zIndex: 100, paddingRight: 30, paddingLeft: 30, top: 10 }}
        >
          <div className="flex flex-wrap w-full items-center justify-between gap-y-2 gap-x-2">
            {flags.show_unassigned_pins.enabled && (
              <Switch
                checkedChildren={t("map.DispatchMap.unassignedStops")}
                unCheckedChildren={t("map.DispatchMap.unassignedStops")}
                checked={showUnassignedTasks}
                onChange={(checked) => {
                  setShowUnassignedTasks(checked)
                  if (checked) {
                    setMapFilters((mapFiltersState) => ({
                      ...mapFiltersState,
                      openedKeys: uniq([
                        ...(mapFiltersState.openedKeys || []),
                        UNASSIGNED_KEY_SLUG,
                      ]),
                    }))
                    setTreeOpenedKeys((treeKeyState) => uniq([...treeKeyState, "unassigned"]))
                  }
                }}
                className={mapFilters?.type === OrderType.ACTIVE ? "hidden" : ""}
              />
            )}
            <div className="flex flex-wrap gap-x-3 ml-auto gap-y-2">
              <MapTeamDriverZoneFilters
                teams={teams}
                zones={zones}
                drivers={allDrivers}
                onDriverChange={onDriverChange}
                onTeamChange={onTeamChange}
                mapFilters={mapFilters}
                setMapFilters={setMapFilters}
              />
              {mapFilters.type === OrderType.ACTIVE && (
                <MapStatusFilterSelect
                  statuses={mapFilters.statuses}
                  setStatuses={(statuses: string[]) => {
                    setMapFilters((mapFilters) => ({ ...mapFilters, statuses }))
                  }}
                />
              )}
              <DateFilter date={mapFilters.dates.range[1]} setDate={onSetDate} />
            </div>
          </div>
        </div>
        {mapContext?.KosmoMap && <mapContext.KosmoMap />}
      </div>
    </div>
  )
}

export default DispatchMap
