import React, { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next"
import { faList } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Dropdown, Button } from "antd"
import { useDrivers } from "@/hooks/useDrivers"
import { isEmpty } from "lodash"

interface MapDropDownActionsProps {
  onCollapse: () => void
  removeAllRoutes: () => void
  setSelectedRowsKeys: Dispatch<SetStateAction<string[]>>
  setTreeOpenedKeys: Dispatch<SetStateAction<string[]>>
  isShowAllRoutesDisabled: boolean
  setWaitingToSelect: any
}

const MapDropdownActions: React.FC<MapDropDownActionsProps> = ({
  onCollapse,
  removeAllRoutes,
  setTreeOpenedKeys,
  setSelectedRowsKeys,
  isShowAllRoutesDisabled,
  setWaitingToSelect,
}) => {
  const { t } = useTranslation()
  const { drivers } = useDrivers()

  const onShowAllRoutes = () => {
    setWaitingToSelect(true)

    const keysToOpen: string[] = []
    drivers.forEach((driver) => {
      if (!isEmpty(driver?.teamIDs)) {
        driver?.teamIDs?.forEach((teamId) => {
          keysToOpen?.push(`${teamId}:${driver.id}`)
        })
      } else {
        keysToOpen?.push(`org:${driver?.id}`)
      }
    })

    setTreeOpenedKeys(keysToOpen)
  }

  const onHideAllRoutes = () => {
    removeAllRoutes()
    setSelectedRowsKeys(() => [])
  }

  const items = [
    {
      label: (
        <div onClick={onCollapse} className="select-none cursor-pointer">
          {t("map.MapDropdownActions.collapseAll")}
        </div>
      ),
      key: "collapseAll",
    },
    {
      label: (
        <div onClick={onHideAllRoutes} className="select-none cursor-pointer">
          {t("map.MapDropdownActions.hideRoutes")}
        </div>
      ),
      key: "hideRoutes",
    },
  ]
  if (!isShowAllRoutesDisabled) {
    items.push({
      label: (
        <div onClick={onShowAllRoutes} className="select-none cursor-pointer">
          {t("map.MapDropdownActions.showAllRoutes")}
        </div>
      ),
      key: "showRoutes",
    })
  }

  return (
    <Dropdown menu={{ items }} trigger={["click"]} className="map-dropdown-actions">
      <Button size="middle">
        <FontAwesomeIcon icon={faList} style={{ fontSize: 12 }} />
      </Button>
    </Dropdown>
  )
}

export default MapDropdownActions
